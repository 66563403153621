/**
 *  Kinty / App / Components / Hook Form Components / Text Field
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Hook Form text field
 *
 */

/** Dependencies */
import React from "react";
import { TextInput, TextInputProps } from "@kinty-app/ui";
import { Control, Controller, ControllerProps, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

/** Props */
export type TextFieldElementProps<T extends FieldValues = FieldValues> = Omit<
  TextInputProps,
  "name"
> & {
  validation?: ControllerProps["rules"];
  name: Path<T>;
  control: Control<T>;
};

export const TextField = <TFieldValues extends FieldValues = FieldValues>({
  validation = {},
  name,
  control,
  disabled,
  message,
  ...rest
}: TextFieldElementProps<TFieldValues>): JSX.Element => (
  <Controller
    name={name}
    control={control}
    rules={validation}
    render={({
      field: { value, onChange, onBlur, ref },
      fieldState: { error, isTouched },
      formState: { isSubmitting },
    }) => (
      <TextInput
        {...rest}
        name={name}
        value={value ?? ""}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        state={error ? "error" : undefined}
        disabled={disabled ?? isSubmitting}
        message={isTouched && error ? error.message : message}
      />
    )}
  />
);
