/**
 *  Kinty / App / Features / Strategies / Pages / Empty
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Empty page in case the user doesn't have any watch lists
 *
 */

/** Dependencies */
import React from "react";
import { Link } from "react-router-dom";
import { Button, Caption } from "@kinty-app/ui";

/** Local Dependencies */
import animationData from "../../../animations/12-article-not-found.json";
import { Lottie } from "../../../utils/Lottie";

/** Styles */
import styles from "./StrategiesEmpty.module.scss";

/** Props */
interface StrategiesEmptyProps {}

export const StrategiesEmpty: React.FC<StrategiesEmptyProps> = () => {
  return (
    <div className={styles.root}>
      <div className={styles.animation}>
        <Lottie animationData={animationData} height={550} width={300} />
      </div>
      <Caption variant="regular">
        It seems that you don't have any strategies yet!
      </Caption>
      <Button
        className={styles.button}
        component={Link}
        to="/strategies/create"
      >
        Create a strategy
      </Button>
    </div>
  );
};
