/**
 *  Kinty / App / Features / Strategies / Pages / Strategy
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategies Page
 *
 */

/** Dependencies */
import React from "react";
import { StrategyDetailResponse } from "@kinty-app/api";
import {
  Headline,
  Card,
  Separator,
  Table,
  TableHead,
  TableHeadRow,
  TableHeadCell,
} from "@kinty-app/ui";

/** Local Dependencies */
import { StrategyTrade } from "./StrategyTrade";

/** Styles */
import styles from "./Strategy.module.scss";

/** Props */
interface StrategyProps {
  strategy?: StrategyDetailResponse;
}

export const Strategy: React.FC<StrategyProps> = ({ strategy }) => {
  if (!strategy) {
    return null;
  }
  return (
    <Card className={styles.root}>
      <div className={styles.header}>
        <Headline level="4" className={styles.title}>
          {strategy.name}
        </Headline>
        <div className={styles.summary}>
          Balance: ${strategy.balance}
          <br />
          Start Balance: ${strategy.startBalance}
        </div>
      </div>
      <Separator className={styles.separator} />
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Symbol / Name</TableHeadCell>
            <TableHeadCell align="right">Open At</TableHeadCell>
            <TableHeadCell align="right">Open Price</TableHeadCell>
            <TableHeadCell align="right">Close At</TableHeadCell>
            <TableHeadCell align="right">Close Price</TableHeadCell>
            <TableHeadCell align="right">Close Reason</TableHeadCell>
            <TableHeadCell align="right">Profit</TableHeadCell>
            <TableHeadCell align="right"></TableHeadCell>
          </TableHeadRow>
        </TableHead>
        {strategy.trades
          .map((trade) => (
            <StrategyTrade
              key={`${trade.symbolKey}-${trade.openedAt}`}
              trade={trade}
            />
          ))
          .reverse()}
      </Table>
    </Card>
  );
};
