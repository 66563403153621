/**
 *  Kinty / App / Pages / Dashboard / Tabs / Losers
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Losers Tab
 *
 */

/** Dependencies */
import React from "react";
import {
  Table,
  TableHead,
  TableHeadRow,
  TableHeadCell,
  TableBody,
  TableRow,
  TableCell,
  SymbolBadge,
  Price,
  Percent,
} from "@kinty-app/ui";
import { useListControllerGetLosers, getSymbolLogoUrl } from "@kinty-app/api";
import Dinero from "dinero.js";

export const Losers: React.FC = () => {
  const { data } = useListControllerGetLosers({});

  return (
    <Table>
      <TableHead>
        <TableHeadRow>
          <TableHeadCell>Symbol / Name</TableHeadCell>
          <TableHeadCell align="right">Price</TableHeadCell>
          <TableHeadCell align="right">Change</TableHeadCell>
          <TableHeadCell align="right">Change (%)</TableHeadCell>
          <TableHeadCell align="right">Volume</TableHeadCell>
          <TableHeadCell align="right">Market Cap</TableHeadCell>
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {(data || []).map((loser) => (
          <TableRow key={loser.symbol}>
            <TableCell>
              <SymbolBadge
                ticker={loser.symbol}
                name={loser.companyName}
                image={getSymbolLogoUrl(loser.symbol)}
              />
            </TableCell>
            <TableCell align="right">
              <Price amount={Math.ceil(loser.price * 100)} currency="USD" />
            </TableCell>
            <TableCell align="right">
              <Price amount={Math.ceil(loser.change * 100)} currency="USD" />
            </TableCell>
            <TableCell align="right">
              <Percent amount={loser.changePercent * 100} />
            </TableCell>
            <TableCell align="right">
              {Dinero({
                amount: loser.volume * 100,
                currency: "USD",
              }).toFormat("$0,0")}
            </TableCell>
            <TableCell align="right">
              {Dinero({
                amount: loser.marketCap * 100,
                currency: "USD",
              }).toFormat("$0,0")}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
