/**
 *  Kinty / App / Features / Strategies / Pages / Strategy / Trade
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategy Trade Page
 *
 */

/** Dependencies */
import React, { useMemo } from "react";
import {
  Trade,
  useOHLCControllerDayOHLC,
  useOHLCControllerMinuteOHLC,
} from "@kinty-app/api";
import Chart from "react-apexcharts";
import { addDays, endOfDay, parseISO, startOfDay, subDays } from "date-fns";

/** Styles */
import styles from "./StrategyTradeDetails.module.scss";

/** Props */
interface StrategyTradeDetailsProps {
  trade: Trade;
}

export const StrategyTradeDetails: React.FC<StrategyTradeDetailsProps> = ({
  trade,
}) => {
  const [openedAt, closedAt] = useMemo(
    () => [parseISO(trade.openedAt), parseISO(trade.closedAt)],
    [trade]
  );

  const { data: dayOHLC, isLoading: loadingDayOHLC } = useOHLCControllerDayOHLC(
    {
      pathParams: { symbolKey: trade.symbolKey },
      queryParams: {
        startAt: subDays(openedAt, 10).toISOString(),
        endAt: addDays(openedAt, 10).toISOString(),
      },
    }
  );

  const { data: minuteOHLC, isLoading: loadingMinnuteOHLC } =
    useOHLCControllerMinuteOHLC({
      pathParams: { symbolKey: trade.symbolKey },
      queryParams: {
        startAt: startOfDay(openedAt).toISOString(),
        endAt: endOfDay(openedAt).toISOString(),
      },
    });

  const daySeries = useMemo(
    () =>
      (dayOHLC || []).map((entry) => [
        parseISO(entry.date).getTime(),
        entry.open,
        entry.high,
        entry.low,
        entry.close,
      ]),
    [dayOHLC]
  );

  const minuteSeries = useMemo(
    () =>
      (minuteOHLC || []).map((entry) => [
        parseISO(entry.date).getTime(),
        entry.open,
        entry.high,
        entry.low,
        entry.close,
      ]),
    [minuteOHLC]
  );

  if (loadingDayOHLC || loadingMinnuteOHLC) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Chart
        options={{
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            candlestick: { wick: { useFillColor: true } },
          },
          xaxis: {
            type: "datetime",
            labels: {
              format: "dd MMM",
            },
          },
          annotations: {
            xaxis: [
              {
                x: startOfDay(subDays(openedAt, 1)).getTime(),
                x2: endOfDay(openedAt).getTime(),
                fillColor: "#B3F7CA",
                label: {
                  text: "Trading day",
                },
              },
            ],
          },
        }}
        series={[{ data: daySeries }]}
        type="candlestick"
        width="500px"
      />
      <Chart
        options={{
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            candlestick: { wick: { useFillColor: true } },
          },
          xaxis: {
            type: "datetime",
            labels: {
              format: "hh:mm",
            },
          },
          annotations: {
            xaxis: [
              {
                x: openedAt.getTime(),
                x2: closedAt.getTime(),
                fillColor: "#B3F7CA",
                label: {
                  text: "Trade",
                },
              },
            ],
          },
        }}
        series={[{ data: minuteSeries }]}
        type="candlestick"
        width="500px"
      />
    </div>
  );
};
