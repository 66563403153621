/**
 *  Kinty / App / Utils / Auth Route
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Route that can be configured based on the authentication and authorization
 *
 */

/** Dependencies */
import React, { useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

/** Local Dependencies */
import { useUser } from "../contexts/user-context";

/**
 * Which private route are we going to redirect in case of no access
 */
const PRIVATE_DEFAULT_ROUTE = "/dashboard/gainers";

/**
 * Which public route are we going to redirect in case of no access
 */
const PUBLIC_DEFAULT_ROUTE = "/sign-in";

interface AuthRouteProps {
  auth?: boolean;
  element?: JSX.Element;
}

export const AuthRoute: React.FC<AuthRouteProps> = ({
  element,
  auth = false,
}) => {
  const { isAuthenticated } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Wrapper for our navigate that includes the state
   */
  const navigateWithState = useCallback(
    (route: string) => {
      navigate(route, { state: { from: location } });
    },
    [navigate, location]
  );

  /**
   * Redirect to public or private route in case there is no element to render
   */
  useEffect(() => {
    if (!element) {
      return navigateWithState(
        isAuthenticated ? PRIVATE_DEFAULT_ROUTE : PUBLIC_DEFAULT_ROUTE
      );
    }
    if (isAuthenticated && !auth) {
      return navigateWithState(PRIVATE_DEFAULT_ROUTE);
    }
    if (!isAuthenticated && auth) {
      return navigateWithState(PUBLIC_DEFAULT_ROUTE);
    }
  }, [navigateWithState, element, isAuthenticated, auth]);

  return element || null;
};
