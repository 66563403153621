/**
 *  Kinty / App / Features / Strategies / Pages / Create
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategies Create Page
 *
 */

/** Dependencies */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Headline, Body, Caption } from "@kinty-app/ui";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useDebounce } from "rooks";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { fetchStrategyControllerGetStatistics } from "@kinty-app/api";

/** Local Dependencies */
import {
  TextField,
  SelectField,
} from "../../../components/hook-form-components";

/** Styles */
import styles from "./StrategiesCreate.module.scss";

/** Error Messages map */
enum ErrorMessage {
  "REQUIRED" = "This field is required",
}

/** The Watchlist Name Schema */
const StrategyCreateSchema = Yup.object().shape({
  name: Yup.string().required(ErrorMessage.REQUIRED),
  balance: Yup.string().required(ErrorMessage.REQUIRED),
  funds: Yup.string().required(ErrorMessage.REQUIRED),
  difference: Yup.string().required(ErrorMessage.REQUIRED),
  stopLoss: Yup.string().required(ErrorMessage.REQUIRED),
  profitGain: Yup.string().required(ErrorMessage.REQUIRED),
});

/** The Create Strategy Form Data */
export interface StrategiesCreateFormData {
  name: string;
  balance: string;
  funds: string;
  difference: string;
  stopLoss: string;
  profitGain: string;
  history: "1w" | "2w" | "1m" | "2m" | "3m" | "6m" | "1y";
  condition: "GAIN" | "LOSS";
  action: "BUY" | "SELL";
}

/** Props */
interface StrategiesCreateProps {
  onSubmit: (values: StrategiesCreateFormData) => void;
}

export const StrategiesCreate: React.FC<StrategiesCreateProps> = ({
  onSubmit,
}) => {
  const [totalTrades, setTotalTrades] = useState<number | null>(null);

  const { handleSubmit, control, watch } = useForm<StrategiesCreateFormData>({
    defaultValues: {
      name: "",
      balance: "",
      funds: "",
      difference: "",
      stopLoss: "",
      profitGain: "",
      history: "1m",
      condition: "GAIN",
      action: "BUY",
    },
    resolver: yupResolver(StrategyCreateSchema),
  });

  const [balance, funds, difference, stopLoss, profitGain, history, condition] =
    watch([
      "balance",
      "funds",
      "difference",
      "stopLoss",
      "profitGain",
      "history",
      "condition",
    ]);

  const getStatistics = useCallback(
    (
      data: Pick<
        StrategiesCreateFormData,
        "difference" | "history" | "condition"
      >
    ) => {
      if (!data.difference || !data.history || !data.condition) {
        return;
      }
      fetchStrategyControllerGetStatistics({
        queryParams: {
          since: data.history,
          condition: data.condition,
          extendedHoursDifferencePercent: parseInt(data.difference, 10) / 100,
        },
      })
        .then((response) => {
          setTotalTrades(response?.totalTrades || null);
        })
        .catch(() => setTotalTrades(null));
    },
    []
  );

  const getStatisticsDebounced = useDebounce(getStatistics, 500);

  useEffect(() => {
    getStatisticsDebounced({ difference, history, condition });
  }, [difference, history, condition, getStatisticsDebounced]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className={styles.card}>
        <Headline level="4" className={styles.title}>
          New Strategy
        </Headline>
        <div className={styles.formContent}>
          <TextField
            control={control}
            label="Strategy name"
            className={styles.fieldName}
            placeholder="e.g. Short 25"
            name="name"
          />
          <SelectField
            control={control}
            label="History"
            name="history"
            className={styles.fieldType}
            options={[
              {
                id: "none",
                label: "No",
              },
              {
                id: "1m",
                label: "1 Month",
              },
              {
                id: "3m",
                label: "3 Months",
              },
              {
                id: "6m",
                label: "6 Months",
              },
              {
                id: "1y",
                label: "1 Year",
              },
            ]}
          />
        </div>
        <Caption level="2" className={styles.formSubtitle} variant="regular">
          The Strategy name will be used for displaying in the Kinty UI and
          depending on the history value the historical transactions will be
          included.
        </Caption>
      </Card>
      <Card className={classNames(styles.card, styles.formField)}>
        <Body level="1" className={styles.formTitle}>
          Starting balance
        </Body>
        <Caption level="1" className={styles.formSubtitle} variant="bold">
          How much money do you start with and what % of that amount will be
          traded during the day?
        </Caption>
        <div className={styles.formContent}>
          <TextField
            control={control}
            label="Start balance (USD)"
            name="balance"
            className={styles.fieldBalance}
            textAlign="right"
            placeholder="e.g. 2500"
          />
          <TextField
            control={control}
            label="Available for trading (%)"
            name="funds"
            textAlign="right"
            className={styles.fieldFunds}
            placeholder="e.g. 60"
          />
        </div>
        <Caption level="2" className={styles.formSubtitle} variant="regular">
          Be careful with the available for trading percent, since having a
          bigger % means that you could loose money faster, that couldn't work
          in the long run
        </Caption>
      </Card>
      <Card className={classNames(styles.card, styles.formField)}>
        <Body level="1" className={styles.formTitle}>
          Extended Hours Condition
        </Body>
        <Caption level="1" className={styles.formSubtitle} variant="bold">
          What criteria should a stock cover to be bought or sold?
        </Caption>
        <div className={styles.formContent}>
          <SelectField
            control={control}
            label="Condition"
            name="condition"
            className={styles.fieldCondition}
            options={[
              {
                id: "GAIN",
                label: "GAIN",
              },
              {
                id: "LOSS",
                label: "LOSS",
              },
            ]}
          />
          <TextField
            control={control}
            label="Extended Hours Difference (%)"
            name="difference"
            className={styles.fieldDifference}
            textAlign="right"
            placeholder="e.g. 40"
          />
        </div>
        <Caption level="2" className={styles.formSubtitle} variant="regular">
          The condition will only apply for stocks gaining or loosing the
          certain % between the official hours close and official hours open of
          the stock exchange.
        </Caption>
      </Card>
      <Card className={classNames(styles.card, styles.formField)}>
        <Body level="1" className={styles.formTitle}>
          Action
        </Body>
        <Caption level="1" className={styles.formSubtitle} variant="bold">
          What should happen when the stock meets the criteria?
        </Caption>
        <div className={styles.formContent}>
          <SelectField
            control={control}
            label="Action"
            name="action"
            className={styles.fieldAction}
            options={[
              {
                id: "BUY",
                label: "BUY",
              },
              {
                id: "SELL",
                label: "SELL",
              },
            ]}
          />
          <TextField
            control={control}
            label="Stop Loss (%)"
            name="stopLoss"
            className={styles.fieldStopLoss}
            textAlign="right"
            placeholder="e.g. 30"
          />
          <TextField
            control={control}
            label="Profit Gain (%)"
            name="profitGain"
            className={styles.fieldProfitGain}
            textAlign="right"
            placeholder="e.g. 30"
          />
        </div>
        <Caption level="2" className={styles.formSubtitle} variant="regular">
          It will either Buy or Sell the stock at the desired price and when the
          stock reaches profit or loss of certain percent of it's value it will
          close the position. The position will also be closed at the end of the
          official working hours of the stock exchange.
        </Caption>
      </Card>
      <Card
        className={classNames(
          styles.card,
          styles.formField,
          styles.summaryCard
        )}
      >
        <div className={styles.summary}>
          <div>
            <Body level="1" className={styles.formTitle}>
              Summary
            </Body>
            <Caption level="1" className={styles.formSubtitle} variant="bold">
              Starting <strong>1 month ago</strong>, with{" "}
              <strong>$ {balance}</strong>, I am willing to risk{" "}
              <strong>{funds} %</strong> of my funds and re-invest them by
              opening a <strong>buy or sell</strong> position for stocks that
              have <strong>gain / loss</strong> of{" "}
              <strong>{difference} %</strong> in the extended trading. I will
              close the position when the stock price{" "}
              <strong>fells/rises</strong> to <strong>{stopLoss} %</strong> or{" "}
              <strong>fells/rises</strong> to <strong>{profitGain} %</strong>.
              In any case the position will always be closed at the end of the
              official trading hours.
            </Caption>
            {totalTrades !== null && (
              <Caption level="1" className={styles.formSubtitle} variant="bold">
                With the above conditions the strategy will trade{" "}
                <strong>{totalTrades} times</strong> before it reaches today.
              </Caption>
            )}
          </div>
          <div className={styles.buttons}>
            <Button
              variant="secondary"
              size="small"
              className={styles.button}
              type="submit"
              component={Link}
              to="/strategies"
            >
              Cancel
            </Button>
            <Button className={styles.button} type="submit">
              Start Strategy
            </Button>
          </div>
        </div>
      </Card>
    </form>
  );
};
