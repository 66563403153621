/**
 *  Kinty / App / Components / Credentials Layout
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Credentials layout is used in the Sign In / Forgotten Password and Reset Password
 *  screens.
 *
 */

/** Dependencies */
import React, { useCallback } from "react";
import classNames from "classnames";
import { Headline, IconButton, Icons, Theme } from "@kinty-app/ui";

/** Styles */
import styles from "./CredentialsLayout.module.scss";

/** Local Dependencies */
import { LogoFull } from "../Logo/LogoFull";
import { useTheme } from "../../contexts/theme-context";

/** Props */
type CredentialsLayoutProps = {
  imageClassName?: string;
  header?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
};

export const CredentialsLayout: React.FC<CredentialsLayoutProps> = ({
  children,
  header,
  imageClassName,
  title,
}) => {
  const { setTheme, theme } = useTheme();
  const handleThemeChange = useCallback(() => {
    setTheme(theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
  }, [setTheme, theme]);

  return (
    <div className={styles.root}>
      <div className={styles.banner}>
        <LogoFull className={styles.logo} />
        <div className={classNames(styles.image, imageClassName)} />
      </div>
      <div className={styles.form}>
        <div className={styles.wrapper}>
          <div className={styles.header}>{header}</div>
          <div className={styles.content}>
            <div className={styles.contentWrapper}>
              <Headline level="3" className={styles.title}>
                {title}
              </Headline>
              {children}
            </div>
          </div>
        </div>
        <IconButton
          icon={
            theme === Theme.LIGHT
              ? Icons.brightnessDownLine
              : Icons.brightnessUpLine
          }
          onClick={handleThemeChange}
          className={styles.themeToggle}
        />
      </div>
    </div>
  );
};
