/**
 *  Kinty / App / Features / Strategies / Create
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategies Create container
 *
 */

/** Dependencies */
import React, { useCallback } from "react";
import { useStrategyControllerCreateStrategy } from "@kinty-app/api";
import { useNavigate } from "react-router-dom";

/** Local Dependencies */
import { useNotifications } from "../../contexts/notifications-context";

/** Pages */
import {
  StrategiesCreate,
  StrategiesCreateFormData,
} from "./pages/StrategiesCreate";

export const StrategiesCreateContainer: React.FC = () => {
  const navigate = useNavigate();
  const { add } = useNotifications();

  const { mutate: createStrategy } = useStrategyControllerCreateStrategy({
    onSuccess: async (data) => {
      navigate(`/strategies/${data.id}`);
    },
    onError: (error) => {
      add({
        id: error.message,
        type: "error",
        content: error.message,
      });
    },
  });

  const handleCreate = useCallback(
    (values: StrategiesCreateFormData) => {
      createStrategy({
        body: {
          name: values.name,
          startBalance: parseInt(values.balance, 10),
          since: values.history,
          availableFundsPercent: parseInt(values.funds, 10) / 100,
          condition: values.condition,
          extendedHoursDifferencePercent: parseInt(values.difference, 10) / 100,
          action: values.action,
          stopLossPercent: parseInt(values.stopLoss, 10) / 100,
          profitGainPercent: parseInt(values.profitGain, 10) / 100,
        },
      });
    },
    [createStrategy]
  );

  return <StrategiesCreate onSubmit={handleCreate} />;
};
