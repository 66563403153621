/**
 *  Kinty / App / Contexts / User
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The user context
 *
 */

/** Dependencies */
import React, { useContext, createContext, useMemo } from "react";
import { useAuthControllerCheck, CheckResponse } from "@kinty-app/api";

interface UserContextValue {
  isInitialized?: boolean;
  isAuthenticated?: boolean;
  refresh?: () => void;
  user?: CheckResponse | null;
}

const UserContext = createContext<UserContextValue>({ isInitialized: false });

export const useUser = (): UserContextValue => {
  const values = useContext(UserContext);
  if (!values.refresh) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return values;
};

interface UserProviderProps {
  children?: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { data, refetch, isFetched, isError } = useAuthControllerCheck(
    {},
    {
      retry: 0,
      refetchOnWindowFocus: false,
      retryOnMount: false,
    }
  );
  const value = useMemo(
    () => ({
      user: data,
      isAuthenticated: !isError && !!data,
      isInitialized: isFetched,
      refresh: refetch,
    }),
    [refetch, isFetched, data, isError]
  );
  return <UserContext.Provider value={value} children={children} />;
};
