/**
 *  Kinty / App / Components / Logo Full
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Primabuild Logo Full Scale as a react component
 *
 */

/** Dependencies */
import React from "react";

interface LogoFullProps {
  className?: string;
}

export const LogoFull: React.FC<LogoFullProps> = ({ className }) => (
  <svg
    width="144px"
    height="47px"
    viewBox="0 0 144 47"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="linearGradient-ljb1spzoxg-1"
      >
        <stop stopColor="#7EA4FF" stopOpacity="0.28431627" offset="0%" />
        <stop stopColor="#1448C4" stopOpacity="0.415279789" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="linearGradient-ljb1spzoxg-2"
      >
        <stop stopColor="#7EA4FF" stopOpacity="0.27" offset="0%" />
        <stop stopColor="#1448C4" stopOpacity="0.42" offset="100%" />
      </linearGradient>
      <path
        d="M26.9079659,1.03547073 L44.558601,10.6146337 C45.8477957,11.3142917 46.6506351,12.663451 46.6506351,14.1302654 L46.6506351,32.8697346 C46.6506351,34.336549 45.8477957,35.6857083 44.558601,36.3853663 L26.9079659,45.9645293 C25.7179652,46.6103537 24.2820348,46.6103537 23.0920341,45.9645293 L5.44139905,36.3853663 C4.15220426,35.6857083 3.34936491,34.336549 3.34936491,32.8697346 L3.34936491,14.1302654 C3.34936491,12.663451 4.15220426,11.3142917 5.44139905,10.6146337 L23.0920341,1.03547073 C24.2820348,0.389646332 25.7179652,0.389646332 26.9079659,1.03547073 Z"
        id="path-ljb1spzoxg-3"
      />
      <linearGradient
        x1="100%"
        y1="20.9750567%"
        x2="15.5411622%"
        y2="79.0249433%"
        id="linearGradient-ljb1spzoxg-4"
      >
        <stop stopColor="#FFFFFF" stopOpacity="0.998745151" offset="0%" />
        <stop stopColor="#DFDFDF" offset="100%" />
      </linearGradient>
      <path
        d="M15.5,26.6363636 C14.67158,26.6363636 14,27.1247636 14,27.7272727 L14,29.9090909 C14,30.5116 14.67158,31 15.5,31 C16.32842,31 17,30.5116 17,29.9090909 L17,27.7272727 C17,27.1247636 16.32842,26.6363636 15.5,26.6363636 Z M18.5,24.9393939 C18.5,24.2699394 19.17158,23.7272727 20,23.7272727 C20.828435,23.7272727 21.5,24.2699394 21.5,24.9393939 L21.5,29.7878788 C21.5,30.4573333 20.828435,31 20,31 C19.17158,31 18.5,30.4573333 18.5,29.7878788 L18.5,24.9393939 Z M24.5,17.9090909 C23.67155,17.9090909 23,18.3975018 23,19 L23,29.9090909 C23,30.5116 23.67155,31 24.5,31 C25.32845,31 26,30.5116 26,29.9090909 L26,19 C26,18.3975127 25.32845,17.9090909 24.5,17.9090909 Z M27.5,20.5272727 C27.5,19.8846196 28.17155,19.3636364 29,19.3636364 C29.82845,19.3636364 30.5,19.884608 30.5,20.5272727 L30.5,29.8363636 C30.5,30.47904 29.82845,31 29,31 C28.17155,31 27.5,30.47904 27.5,29.8363636 L27.5,20.5272727 Z M33.5,15 C32.67155,15 32,15.51168 32,16.1428571 L32,29.8571429 C32,30.4883429 32.67155,31 33.5,31 C34.32845,31 35,30.4883429 35,29.8571429 L35,16.1428571 C35,15.51168 34.32845,15 33.5,15 Z"
        id="path-ljb1spzoxg-5"
      />
      <filter
        x="-33.3%"
        y="-31.2%"
        width="166.7%"
        height="187.5%"
        filterUnits="objectBoundingBox"
        id="filter-ljb1spzoxg-6"
      >
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0.35476375   0 0 0 0 0.35476375   0 0 0 0 0.35476375  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g
      id="Logo-v2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Logo-And-Text-(-Small-)-Copy"
        transform="translate(0.000000, -1.000000)"
      >
        <g id="Logo" transform="translate(0.000000, 1.000000)">
          <g id="Icon">
            <g id="Background">
              <use
                fill="url(#linearGradient-ljb1spzoxg-1)"
                xlinkHref="#path-ljb1spzoxg-3"
              />
              <path
                stroke="url(#linearGradient-ljb1spzoxg-2)"
                strokeWidth="1.5"
                d="M23.4497777,1.69465167 C24.4166533,1.16991935 25.5833467,1.16991935 26.5502223,1.69465167 L26.5502223,1.69465167 L44.2008574,11.2738147 C44.7245927,11.5580507 45.1495372,11.9742167 45.4435478,12.4682971 C45.7375584,12.9623774 45.9006351,13.5343721 45.9006351,14.1302654 L45.9006351,14.1302654 L45.9006351,32.8697346 C45.9006351,33.4656279 45.7375584,34.0376226 45.4435478,34.5317029 C45.1495372,35.0257833 44.7245927,35.4419493 44.2008574,35.7261853 L44.2008574,35.7261853 L26.5502223,45.3053483 C25.5833467,45.8300806 24.4166533,45.8300806 23.4497777,45.3053483 L23.4497777,45.3053483 L5.79914265,35.7261853 C5.27540726,35.4419493 4.85046283,35.0257833 4.55645224,34.5317029 C4.26244165,34.0376226 4.09936491,33.4656279 4.09936491,32.8697346 L4.09936491,32.8697346 L4.09936491,14.1302654 C4.09936491,13.5343721 4.26244165,12.9623774 4.55645224,12.4682971 C4.85046283,11.9742167 5.27540726,11.5580507 5.79914265,11.2738147 L5.79914265,11.2738147 Z"
                fill="#3772FF"
              />
            </g>
            <g id="Shape">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-ljb1spzoxg-6)"
                xlinkHref="#path-ljb1spzoxg-5"
              />
              <use
                fill="url(#linearGradient-ljb1spzoxg-4)"
                fillRule="evenodd"
                xlinkHref="#path-ljb1spzoxg-5"
              />
            </g>
          </g>
          <path
            d="M69.524,36 L69.524,24.804 L78.668,36 L85.04,36 L74.384,23.328 L84.752,10.872 L78.668,10.872 L69.524,22.14 L69.524,10.872 L64.484,10.872 L64.484,36 L69.524,36 Z M87.908,13.68 C89.672,13.68 91.004,12.384 91.004,10.728 C91.004,9.072 89.672,7.776 87.908,7.776 C86.108,7.776 84.812,9.072 84.812,10.728 C84.812,12.384 86.108,13.68 87.908,13.68 Z M90.392,36 L90.392,16.056 L85.352,16.056 L85.352,36 L90.392,36 Z M97.4,36 L97.4,24.984 C97.4,21.78 99.164,20.052 101.9,20.052 C104.564,20.052 106.328,21.78 106.328,24.984 L106.328,36 L111.368,36 L111.368,24.3 C111.368,18.792 108.056,15.768 103.412,15.768 C100.856,15.768 98.696,16.848 97.4,18.54 L97.4,16.056 L92.36,16.056 L92.36,36 L97.4,36 Z M123.488,36 L123.488,31.752 L121.148,31.752 C119.564,31.752 119.024,31.176 119.024,29.844 L119.024,20.196 L123.488,20.196 L123.488,16.056 L119.024,16.056 L119.024,11.124 L113.948,11.124 L113.948,16.056 L111.572,16.056 L111.572,20.196 L113.948,20.196 L113.948,29.808 C113.948,34.38 116.504,36 120.32,36 L123.488,36 Z M130.856,45.432 L143.204,16.056 L137.84,16.056 L132.62,29.988 L127.472,16.056 L121.82,16.056 L129.812,35.496 L125.492,45.432 L130.856,45.432 Z"
            id="Kinty"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);
