/**
 *  Kinty / App / Dialogs / Destructive Dialog
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Dialog that asks for deleting / removing entity
 *
 */

/** Dependencies */
import React from "react";
import { Dialog, Button, Body } from "@kinty-app/ui";

/** Styles */
import styles from "./DestructiveDialog.module.scss";

/** Props */
interface DestructiveDialogProps {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  message: string;
  title: string;
}

export const DestructiveDialog: React.FC<DestructiveDialogProps> = ({
  onClose,
  onSubmit,
  open,
  title,
  message,
}) => (
  <Dialog
    title={title}
    onClose={onClose}
    visible={open}
    className={styles.root}
  >
    <div className={styles.content}>
      <Body level="2">{message}</Body>
      <div className={styles.actions}>
        <Button className={styles.button} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={styles.button}
          onClick={onSubmit}
          variant="secondary"
        >
          OK
        </Button>
      </div>
    </div>
  </Dialog>
);
