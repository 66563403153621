/**
 *  Kinty / App / Features / Strategies
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategies
 *
 */

/** Dependencies */
import React from "react";
import { Route, Routes } from "react-router-dom";

/** Pages */
import { StrategiesListContainer } from "./StrategiesListContainer";
import { StrategiesCreateContainer } from "./StrategiesCreateContainer";
import { StrategyContainer } from "./StrategyContainer";

export const Strategies: React.FC = () => {
  return (
    <Routes>
      <Route path="/create" element={<StrategiesCreateContainer />} />
      <Route path="/:id" element={<StrategyContainer />} />
      <Route path="/" element={<StrategiesListContainer />} />
    </Routes>
  );
};
