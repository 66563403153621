/**
 *  Kinty / App / Pages / Sign Up
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Sign-Up Page
 *
 */

/** Dependencies */
import React, { useCallback } from "react";
import classNames from "classnames";
import { useParams, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Button,
  Caption,
  Link,
  Notification,
  ButtonText,
  Separator,
  TextInput,
} from "@kinty-app/ui";
import {
  UserException,
  useUserControllerGetInvite,
  useUserControllerSignUp,
} from "@kinty-app/api";

/** Local Dependencies */
import { CredentialsLayout } from "../../components/CredentialsLayout/CredentialsLayout";
import { TextField } from "../../components/hook-form-components";

/** Styles */
import styles from "./SignUp.module.scss";

/** Error Messages map */
enum ErrorMessage {
  "INVALID_TOKEN" = "Error while trying to get the token!",
  "PASSWORD_REQUIRED" = "Password is required",
  "NAME_REQUIRED" = "Name is required",
  "CONFIRM_PASSWORD_REQUIRED" = "Password confirmation is required",
  "CONFIRM_PASSWORD_MISMATCH" = "Passwords don't match",
}

/** Success Messages map */
enum SuccessMessage {
  "SIGN_UP" = "You have been successfully signed up.",
}

/** The Sign-Up data structure */
interface SignUpFormData {
  name: string;
  password: string;
  confirmPassword: string;
}

/** The Sign-Up Schema */
const SignUpSchema = Yup.object().shape({
  name: Yup.string().required(ErrorMessage.NAME_REQUIRED),
  password: Yup.string().required(ErrorMessage.PASSWORD_REQUIRED),
  confirmPassword: Yup.string()
    .required(ErrorMessage.CONFIRM_PASSWORD_REQUIRED)
    .equals([Yup.ref("password")], ErrorMessage.CONFIRM_PASSWORD_MISMATCH),
});

export const SignUp: React.FC = () => {
  const params = useParams();
  const token = params.token || "";

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<SignUpFormData>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(SignUpSchema),
  });

  const {
    mutate: signUp,
    isSuccess,
    error: signUpError,
  } = useUserControllerSignUp();

  const { data, error, isError } = useUserControllerGetInvite(
    { pathParams: { id: token } },
    { retry: false }
  );

  const onSubmit = useCallback(
    async ({ name, password }: SignUpFormData) => {
      await signUp(
        { body: { name, password, token } },
        { onSuccess: () => reset() }
      );
    },
    [signUp, token, reset]
  );

  return (
    <CredentialsLayout
      imageClassName={styles.image}
      title="Sign Up"
      header={
        <ButtonText level="2">
          Already have an account?{" "}
          <Link component={NavLink} to="/sign-in">
            Sign in
          </Link>
        </ButtonText>
      }
    >
      <Caption level="2" variant="regular" className={styles.text}>
        You have been invited to Kinty. Use the form below to sign up.
      </Caption>
      <Separator />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {error && !isSubmitting && (
          <Notification type="error" className={styles.notification}>
            {error.message === UserException.TOKEN_NOT_FOUND
              ? ErrorMessage.INVALID_TOKEN
              : error.message}
          </Notification>
        )}
        {signUpError && !isSubmitting && (
          <Notification type="error" className={styles.notification}>
            {signUpError.message}
          </Notification>
        )}
        {isSuccess && !isSubmitting && (
          <Notification type="success" className={styles.notification}>
            {SuccessMessage.SIGN_UP}
          </Notification>
        )}
        <TextInput
          label="E-mail"
          name="email"
          disabled
          value={data?.email}
          className={styles.input}
        />
        <TextField
          control={control}
          className={styles.input}
          label="Name"
          name="name"
        />
        <TextField
          control={control}
          className={styles.input}
          label="Password"
          type="password"
          name="password"
        />
        <TextField
          control={control}
          className={styles.input}
          label="Password Confirmation"
          type="password"
          name="confirmPassword"
        />
        <Button
          type="submit"
          className={classNames(styles.submit)}
          stretch
          disabled={isSubmitting || isError}
        >
          Sign Up
        </Button>
      </form>
    </CredentialsLayout>
  );
};
