/**
 *  Kinty / App / Features / Strategies / Pages / Strategy / Trade
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategy Trade Page
 *
 */

/** Dependencies */
import React, { useCallback, useState } from "react";
import { format, parseISO } from "date-fns";
import { getSymbolLogoUrl, Trade } from "@kinty-app/api";
import {
  IconButton,
  Icons,
  Price,
  SymbolBadge,
  TableBody,
  TableCell,
  TableRow,
} from "@kinty-app/ui";

/** Local Dependencies */
import { StrategyTradeDetails } from "./StrategyTradeDetails";

/** Styles */
import styles from "./StrategyTrade.module.scss";

/** Props */
interface StrategyTradeProps {
  trade: Trade;
}

export const StrategyTrade: React.FC<StrategyTradeProps> = ({ trade }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleClick = useCallback(() => {
    setExpanded((value) => !value);
  }, []);

  return (
    <TableBody className={styles.root}>
      <TableRow>
        <TableCell>
          <SymbolBadge
            ticker={trade.symbolKey}
            name="My Symbol"
            image={getSymbolLogoUrl(trade.symbolKey)}
          />
        </TableCell>
        <TableCell align="right">
          {format(parseISO(trade.openedAt), "dd MMM yyyy / HH:mm")}
        </TableCell>
        <TableCell align="right">
          <Price amount={Math.ceil(trade.openPrice * 100)} currency="USD" />
        </TableCell>
        <TableCell align="right">
          {format(parseISO(trade.closedAt), "dd MMM yyyy / HH:mm")}
        </TableCell>
        <TableCell align="right">
          <Price amount={Math.ceil(trade.closePrice * 100)} currency="USD" />
        </TableCell>
        <TableCell align="right">{trade.reason}</TableCell>
        <TableCell align="right">
          <Price
            amount={Math.ceil(trade.profit * trade.amount * 100)}
            currency="USD"
            className={trade.profit > 0 ? styles.profit : styles.loss}
          />
        </TableCell>
        <TableCell>
          <IconButton
            icon={
              expanded ? Icons.arrowUpSquareLine : Icons.arrowDownSquareLine
            }
            onClick={handleToggleClick}
          />
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow>
          <TableCell colSpan={8}>
            <StrategyTradeDetails trade={trade} />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
