/**
 *  Kinty / App / Components / Logo
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The logo for the header
 *
 */

/** Dependencies */
import React from "react";

/** Styles */
import styles from "./Logo.module.scss";

export const Logo: React.FC = () => (
  <svg
    width="102px"
    height="32px"
    viewBox="0 0 102 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
  >
    <defs>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="linearGradient-xt1xlyia2l-1"
      >
        <stop stopColor="#7EA4FF" stopOpacity="0.28431627" offset="0%" />
        <stop stopColor="#1448C4" stopOpacity="0.415279789" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="linearGradient-xt1xlyia2l-2"
      >
        <stop stopColor="#7EA4FF" stopOpacity="0.27" offset="0%" />
        <stop stopColor="#1448C4" stopOpacity="0.42" offset="100%" />
      </linearGradient>
      <path
        d="M18.8083771,1.04988982 L29.4345839,6.86203617 C30.7173499,7.56366226 31.5150957,8.90927934 31.5150957,10.3713901 L31.5150957,21.6286099 C31.5150957,23.0907207 30.7173499,24.4363377 29.4345839,25.1379638 L18.8083771,30.9501102 C17.6123286,31.6043049 16.1654492,31.6043049 14.9694007,30.9501102 L4.34319386,25.1379638 C3.06042783,24.4363377 2.26268207,23.0907207 2.26268207,21.6286099 L2.26268207,10.3713901 C2.26268207,8.90927934 3.06042783,7.56366226 4.34319386,6.86203617 L14.9694007,1.04988982 C16.1654492,0.395695064 17.6123286,0.395695064 18.8083771,1.04988982 Z"
        id="path-xt1xlyia2l-3"
      />
      <linearGradient
        x1="100%"
        y1="19.1326531%"
        x2="15.5411622%"
        y2="80.8673469%"
        id="linearGradient-xt1xlyia2l-4"
      >
        <stop stopColor="#FFFFFF" stopOpacity="0.998745151" offset="0%" />
        <stop stopColor="#DFDFDF" offset="100%" />
      </linearGradient>
      <path
        d="M10.5555556,18 C10.0032756,18 9.55555556,18.335775 9.55555556,18.75 L9.55555556,20.25 C9.55555556,20.664225 10.0032756,21 10.5555556,21 C11.1078356,21 11.5555556,20.664225 11.5555556,20.25 L11.5555556,18.75 C11.5555556,18.335775 11.1078356,18 10.5555556,18 Z M12.5555556,16.8333333 C12.5555556,16.3730833 13.0032756,16 13.5555556,16 C14.1078456,16 14.5555556,16.3730833 14.5555556,16.8333333 L14.5555556,20.1666667 C14.5555556,20.6269167 14.1078456,21 13.5555556,21 C13.0032756,21 12.5555556,20.6269167 12.5555556,20.1666667 L12.5555556,16.8333333 Z M16.5555556,12 C16.0032556,12 15.5555556,12.3357825 15.5555556,12.75 L15.5555556,20.25 C15.5555556,20.664225 16.0032556,21 16.5555556,21 C17.1078556,21 17.5555556,20.664225 17.5555556,20.25 L17.5555556,12.75 C17.5555556,12.33579 17.1078556,12 16.5555556,12 Z M18.5555556,13.8 C18.5555556,13.358176 19.0032556,13 19.5555556,13 C20.1078556,13 20.5555556,13.358168 20.5555556,13.8 L20.5555556,20.2 C20.5555556,20.64184 20.1078556,21 19.5555556,21 C19.0032556,21 18.5555556,20.64184 18.5555556,20.2 L18.5555556,13.8 Z M22.5555556,10 C22.0032556,10 21.5555556,10.35178 21.5555556,10.7857143 L21.5555556,20.2142857 C21.5555556,20.6482357 22.0032556,21 22.5555556,21 C23.1078556,21 23.5555556,20.6482357 23.5555556,20.2142857 L23.5555556,10.7857143 C23.5555556,10.35178 23.1078556,10 22.5555556,10 Z"
        id="path-xt1xlyia2l-5"
      />
      <filter
        x="-50.0%"
        y="-45.5%"
        width="200.0%"
        height="227.3%"
        filterUnits="objectBoundingBox"
        id="filter-xt1xlyia2l-6"
      >
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0.35476375   0 0 0 0 0.35476375   0 0 0 0 0.35476375  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g
      id="Logo-v2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Logo-And-Text-(-Small-)"
        transform="translate(0.000000, -1.000000)"
      >
        <g id="Logo" transform="translate(0.444444, 1.000000)">
          <g id="Icon">
            <g id="Background">
              <use
                fill="url(#linearGradient-xt1xlyia2l-1)"
                xlinkHref="#path-xt1xlyia2l-3"
              />
              <path
                stroke="url(#linearGradient-xt1xlyia2l-2)"
                strokeWidth="1.5"
                d="M15.3293047,1.70789368 C16.3010941,1.17636044 17.4766837,1.17636044 18.4484731,1.70789368 L18.4484731,1.70789368 L29.0746799,7.52004003 C29.5958036,7.80507563 30.0184075,8.2209219 30.3107306,8.71400489 C30.6030536,9.20708789 30.7650957,9.77740761 30.7650957,10.3713901 L30.7650957,10.3713901 L30.7650957,21.6286099 C30.7650957,22.2225924 30.6030536,22.7929121 30.3107306,23.2859951 C30.0184075,23.7790781 29.5958036,24.1949244 29.0746799,24.47996 L29.0746799,24.47996 L18.4484731,30.2921063 C17.4766837,30.8236396 16.3010941,30.8236396 15.3293047,30.2921063 L15.3293047,30.2921063 L4.7030979,24.47996 C4.1819742,24.1949244 3.75937024,23.7790781 3.46704721,23.2859951 C3.17472418,22.7929121 3.01268207,22.2225924 3.01268207,21.6286099 L3.01268207,21.6286099 L3.01268207,10.3713901 C3.01268207,9.77740761 3.17472418,9.20708789 3.46704721,8.71400489 C3.75937024,8.2209219 4.1819742,7.80507563 4.7030979,7.52004003 L4.7030979,7.52004003 Z"
                fill="#3772FF"
              />
            </g>
            <g id="Shape">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-xt1xlyia2l-6)"
                xlinkHref="#path-xt1xlyia2l-5"
              />
              <use
                fill="url(#linearGradient-xt1xlyia2l-4)"
                fillRule="evenodd"
                xlinkHref="#path-xt1xlyia2l-5"
              />
            </g>
          </g>
          <path
            d="M46.0655556,24 L46.0655556,16.536 L52.1615556,24 L56.4095556,24 L49.3055556,15.552 L56.2175556,7.248 L52.1615556,7.248 L46.0655556,14.76 L46.0655556,7.248 L42.7055556,7.248 L42.7055556,24 L46.0655556,24 Z M59.8215556,9.12 C60.9975556,9.12 61.8855556,8.256 61.8855556,7.152 C61.8855556,6.048 60.9975556,5.184 59.8215556,5.184 C58.6215556,5.184 57.7575556,6.048 57.7575556,7.152 C57.7575556,8.256 58.6215556,9.12 59.8215556,9.12 Z M61.4775556,24 L61.4775556,10.704 L58.1175556,10.704 L58.1175556,24 L61.4775556,24 Z M67.6495556,24 L67.6495556,16.656 C67.6495556,14.52 68.8255556,13.368 70.6495556,13.368 C72.4255556,13.368 73.6015556,14.52 73.6015556,16.656 L73.6015556,24 L76.9615556,24 L76.9615556,16.2 C76.9615556,12.528 74.7535556,10.512 71.6575556,10.512 C69.9535556,10.512 68.5135556,11.232 67.6495556,12.36 L67.6495556,10.704 L64.2895556,10.704 L64.2895556,24 L67.6495556,24 Z M86.5415556,24 L86.5415556,21.168 L84.9815556,21.168 C83.9255556,21.168 83.5655556,20.784 83.5655556,19.896 L83.5655556,13.464 L86.5415556,13.464 L86.5415556,10.704 L83.5655556,10.704 L83.5655556,7.416 L80.1815556,7.416 L80.1815556,10.704 L78.5975556,10.704 L78.5975556,13.464 L80.1815556,13.464 L80.1815556,19.872 C80.1815556,22.92 81.8855556,24 84.4295556,24 L86.5415556,24 Z M92.9535556,30.288 L101.185556,10.704 L97.6095556,10.704 L94.1295556,19.992 L90.6975556,10.704 L86.9295556,10.704 L92.2575556,23.664 L89.3775556,30.288 L92.9535556,30.288 Z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);
