/**
 *  Kinty / App / App
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The main application logic
 *
 */

/** Dependencies */
import React, { StrictMode } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { APIProvider } from "@kinty-app/api";

/** Local Dependencies */
import { Layout } from "./components/Layout/Layout";
import { AuthRoute } from "./utils/AuthRoute";
import { UserProvider, useUser } from "./contexts/user-context";
import { NotificationsProvider } from "./contexts/notifications-context";
import { ThemeProvider } from "./contexts/theme-context";
import { SymbolsProvider } from "./contexts/symbols-context";
import { Notifications } from "./components/Notifications/Notifications";
import { Header } from "./components/Header/Header";

/** Pages */
import { SignIn } from "./pages/SignIn/SignIn";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { ForgottenPassword } from "./pages/ForgottenPassword/ForgottenPassword";
import { SignUp } from "./pages/SignUp/SignUp";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { WatchlistsFeature } from "./features/watchlists";
import { StrategiesFeature } from "./features/strategies";

/** Styles */
import "@kinty-app/ui/dist/index.css";
import styles from "./App.module.scss";

export const App: React.FunctionComponent = () => {
  const { isAuthenticated, isInitialized } = useUser();

  if (!isInitialized) {
    return null;
  }

  return (
    <Layout empty={!isAuthenticated}>
      {/** Notifications */}
      <Notifications />

      {/** Header */}
      {isAuthenticated && <Header className={styles.header} />}

      {/** Content */}
      <Routes>
        {/** Private */}
        <Route
          path="/dashboard/*"
          element={<AuthRoute element={<Dashboard />} auth />}
        />
        <Route
          path="/watchlists/:id"
          element={<AuthRoute element={<WatchlistsFeature />} auth />}
        />
        <Route
          path="/watchlists"
          element={<AuthRoute element={<WatchlistsFeature />} auth />}
        />
        <Route
          path="/strategies/*"
          element={<AuthRoute element={<StrategiesFeature />} auth />}
        />
        {/** Public */}
        <Route
          path="/sign-up/:token"
          element={<AuthRoute element={<SignUp />} />}
        />
        <Route path="/sign-in" element={<AuthRoute element={<SignIn />} />} />
        <Route
          path="/forgotten-password"
          element={<AuthRoute element={<ForgottenPassword />} />}
        />
        <Route
          path="/password-reset/:token"
          element={<AuthRoute element={<ResetPassword />} />}
        />
        {/** Redirect all other paths */}
        <Route path="*" element={<AuthRoute />} />
      </Routes>
    </Layout>
  );
};

/**
 * Export the App with its providers
 */
// eslint-disable-next-line
export default () => (
  <StrictMode>
    <Router>
      <APIProvider baseUrl={process.env.REACT_APP_API_URL || "/"}>
        <UserProvider>
          <NotificationsProvider>
            <SymbolsProvider>
              <ThemeProvider>
                <App />
              </ThemeProvider>
            </SymbolsProvider>
          </NotificationsProvider>
        </UserProvider>
      </APIProvider>
    </Router>
  </StrictMode>
);
