/**
 *  Kinty / App / Features / Watchlists / Pages / Empty
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Empty page in case the user doesn't have any watch lists
 *
 */

/** Dependencies */
import React, { useState, useCallback } from "react";
import { Button, Caption } from "@kinty-app/ui";

/** Local Dependencies */
import animationData from "../../../animations/12-article-not-found.json";
import { WatchlistsName, WatchlistNameData } from "../dialogs/WatchlistName";
import { Lottie } from "../../../utils/Lottie";

/** Styles */
import styles from "./WatchlistsEmpty.module.scss";

/** Props */
interface WatchlistsEmptyProps {
  onCreate: (data: WatchlistNameData) => void;
}

export const WatchlistsEmpty: React.FC<WatchlistsEmptyProps> = ({
  onCreate,
}) => {
  const [open, setOpen] = useState(false);
  const handleDialogClose = useCallback(() => setOpen(false), [setOpen]);
  const handleCreateClick = useCallback(() => setOpen(true), [setOpen]);
  const handleSubmit = useCallback(
    (data: WatchlistNameData) => {
      onCreate(data);
      setOpen(false);
    },
    [setOpen, onCreate]
  );

  return (
    <div className={styles.root}>
      <div className={styles.animation}>
        <Lottie animationData={animationData} height={550} width={300} />
      </div>
      <Caption variant="regular">
        It seems that you don't have any watchlists yet!
      </Caption>
      <Button className={styles.button} onClick={handleCreateClick}>
        Create a watchlist
      </Button>
      <WatchlistsName
        open={open}
        type="create"
        onClose={handleDialogClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
