/**
 *  Kinty / App / Pages / Dashboard
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Dashboard Page
 *
 */

/** Dependencies */
import React from "react";
import {
  Card,
  SubNavigation,
  SubNavigationLink,
  SubNavigationLinkActiveClassName,
  Separator,
} from "@kinty-app/ui";
import { Routes, Route } from "react-router-dom";
import classNames from "classnames";

/** Local Dependencies */
import { Gainers } from "./tabs/Gainers";
import { Losers } from "./tabs/Losers";
import { NavLink } from "../../utils/NavLink";

/** Styles */
import styles from "./Dashboard.module.scss";

export const Dashboard: React.FC = () => {
  return (
    <Card className={styles.root}>
      <div className={styles.actions}>
        <SubNavigation>
          <SubNavigationLink
            title="Gainers"
            to="/dashboard/gainers"
            component={NavLink}
            activeClassName={SubNavigationLinkActiveClassName}
          />
          <SubNavigationLink
            title="Losers"
            to="/dashboard/losers"
            component={NavLink}
            activeClassName={SubNavigationLinkActiveClassName}
          />
        </SubNavigation>
      </div>
      <Separator className={styles.separator} />
      <div className={classNames(styles.table)}>
        <Routes>
          <Route path="/gainers" element={<Gainers />} />
          <Route path="/losers" element={<Losers />} />
        </Routes>
      </div>
    </Card>
  );
};
