/**
 *  Kinty / App / Features / Watchlists / Dialogs / Symbols
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Dialog that renders the input field for the name of the watchlist
 *
 */

/** Dependencies */
import React, { useState, useCallback, useMemo } from "react";
import {
  Icons,
  Dialog,
  TextInput,
  Table,
  TableHead,
  TableHeadRow,
  TableHeadCell,
  TableBody,
  TableRow,
  TableCell,
  Caption,
  SymbolBadge,
  IconButton,
} from "@kinty-app/ui";
import { getSymbolLogoUrl } from "@kinty-app/api";

/** Local Dependencies */
import { useSymbols } from "../../../contexts/symbols-context";

/** Styles */
import styles from "./WatchlistSymbols.module.scss";

/** Props */
interface WatchlistSymbolsProps {
  onClose?: () => void;
  onClick: (ticker: string) => void;
  open: boolean;
  selected: string[];
}

export const WatchlistsSymbols: React.FC<WatchlistSymbolsProps> = ({
  onClose,
  onClick,
  selected,
  open,
}) => {
  const { symbols } = useSymbols();
  const [search, setSearch] = useState("");

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setSearch(event.target.value);
    },
    [setSearch]
  );

  const handleClick = useCallback(
    (symbol: string) => onClick(symbol),
    [onClick]
  );

  const results = useMemo(() => {
    if (!search) {
      return symbols.slice(0, 50);
    }
    const lowerCasedSearch = search.toLowerCase();
    return symbols
      .filter(
        ({ symbol, name }) =>
          symbol.toLowerCase().includes(lowerCasedSearch) ||
          name.toLowerCase().includes(lowerCasedSearch)
      )
      .slice(0, 50);
  }, [search, symbols]);

  return (
    <Dialog
      title="Add Symbols"
      onClose={onClose}
      visible={open}
      className={styles.root}
    >
      <div className={styles.search}>
        <TextInput
          onChange={handleChange}
          icon={Icons.searchLine}
          value={search}
          className={styles.input}
          placeholder="Search"
        />
      </div>
      <div className={styles.table}>
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>Symbol</TableHeadCell>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell align="right">Actions</TableHeadCell>
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {results.map((symbol) => (
              <TableRow key={symbol.symbol}>
                <TableCell className={styles.ticker}>
                  <SymbolBadge
                    ticker={symbol.symbol}
                    image={getSymbolLogoUrl(symbol.symbol)}
                  />
                </TableCell>
                <TableCell>
                  <Caption level="1" variant="bold" className={styles.name}>
                    {symbol.name}
                  </Caption>
                </TableCell>
                <TableCell align="right">
                  <div className={styles.actions}>
                    <IconButton
                      icon={
                        selected?.includes(symbol.symbol)
                          ? Icons.minusSquareLine
                          : Icons.plusSquareLine
                      }
                      onClick={() => handleClick(symbol.symbol)}
                      value={symbol.symbol}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Dialog>
  );
};
