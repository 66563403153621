/**
 *  Kinty / App / Pages / Sign In
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Sign-In Page
 *
 */

/** Dependencies */
import React, { useCallback } from "react";
import classNames from "classnames";
import { useNavigate, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button, Link, Notification, ButtonText, Caption } from "@kinty-app/ui";
import { AuthException, useAuthControllerSignIn } from "@kinty-app/api";

/** Local Dependencies */
import { useUser } from "../../contexts/user-context";
import { CredentialsLayout } from "../../components/CredentialsLayout/CredentialsLayout";
import { TextField } from "../../components/hook-form-components";

/** Styles */
import styles from "./SignIn.module.scss";

/** Error Messages map */
enum ErrorMessage {
  "INVALID_CREDENTIALS" = "Invalid credentials",
  "INVALID_EMAIL" = "Invalid e-mail",
  "EMAIL_REQUIRED" = "E-mail is required",
  "PASSWORD_REQUIRED" = "Password is required",
}

/** The Sign-In Form data structure */
interface SignInFormData {
  email: string;
  password: string;
}

/** The Sign-In Schema */
const SignInSchema = Yup.object().shape({
  password: Yup.string().required(ErrorMessage.PASSWORD_REQUIRED),
  email: Yup.string()
    .email(ErrorMessage.INVALID_EMAIL)
    .required(ErrorMessage.EMAIL_REQUIRED),
});

export const SignIn: React.FC = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<SignInFormData>({
    mode: "onBlur",
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(SignInSchema),
  });

  const { refresh } = useUser();
  const navigate = useNavigate();

  const { mutate: signIn, error } = useAuthControllerSignIn({
    onSuccess: () => {
      if (refresh) {
        refresh();
      }
      navigate("/dashboard");
    },
  });

  const onSubmit = useCallback(
    async ({ email, password }: SignInFormData) => {
      await signIn({ body: { email, password } }, { onSuccess: () => reset() });
    },
    [signIn, reset]
  );

  return (
    <CredentialsLayout
      header={
        <ButtonText level="2">
          Sorry, we are currently closed for new registrations.
        </ButtonText>
      }
      title="Sign In"
      imageClassName={classNames(styles.image)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {error && !isSubmitting && (
          <Notification type="error" className={styles.notification}>
            {error.message === AuthException.UNAUTHORIZED
              ? ErrorMessage.INVALID_CREDENTIALS
              : error.message}
          </Notification>
        )}
        <TextField
          control={control}
          label="E-mail"
          name="email"
          autoComplete="username"
          className={styles.input}
        />
        <TextField
          control={control}
          label="Password"
          type="password"
          name="password"
          autoComplete="current-password"
          className={styles.input}
        />
        <Caption level="2" variant="bold" className={styles.links}>
          <Link component={NavLink} to="/forgotten-password">
            Forgot password?
          </Link>
        </Caption>
        <Button
          type="submit"
          className={classNames(styles.submit)}
          disabled={isSubmitting}
          stretch
        >
          Sign In
        </Button>
      </form>
    </CredentialsLayout>
  );
};
