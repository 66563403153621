/**
 *  Kinty / App / Features / Strategies
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategies
 *
 */

/** Dependencies */
import React from "react";
import { useStrategyControllerGetStrategies } from "@kinty-app/api";

/** Pages */
import { StrategiesList } from "./pages/StrategiesList";
import { StrategiesEmpty } from "./pages/StrategiesEmpty";

export const StrategiesListContainer: React.FC = () => {
  const { data, isLoading } = useStrategyControllerGetStrategies({});

  if (isLoading) {
    return null;
  }

  if (data?.length === 0) {
    return <StrategiesEmpty />;
  } else {
    return <StrategiesList strategies={data || []} />;
  }
};
