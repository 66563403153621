/**
 *  Kinty / Web / Utils / NavLink
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Wrapper for React Router's Nav Link component that supports activeClassName
 *
 */

/** Dependencies */
import React from "react";
import {
  NavLink as BaseNavLink,
  NavLinkProps as BaseNavLinkProps,
} from "react-router-dom";
import classNames from "classnames";

/** Props */
interface NavLinkProps extends Omit<BaseNavLinkProps, "className"> {
  activeClassName?: string;
  className?: string;
}

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ activeClassName, className, ...props }, ref) => {
    return (
      <BaseNavLink
        ref={ref}
        className={({ isActive }) =>
          classNames(isActive && activeClassName, className)
        }
        {...props}
      />
    );
  }
);
