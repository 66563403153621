/**
 *  Kinty / Web / Utils / Lottie
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Wrapper for Lottie's animation library
 *
 */

/** Dependencies */
import React, { useRef, useEffect } from "react";
import lottie from "lottie-web";

/** Props */
interface LottieProps {
  width: number;
  height: number;
  animationData: any;
}

export const Lottie: React.FC<LottieProps> = ({
  width,
  height,
  animationData,
}) => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<any>();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        loop: true,
        autoplay: true,
        animationData,
        container: element.current,
      });
    }
  }, [animationData]);

  return <div style={{ width, height }} ref={element}></div>;
};
