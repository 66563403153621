/**
 *  Kinty / App / Features / Strategies / Strategy
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategy view
 *
 */

/** Dependencies */
import React from "react";
import { useStrategyControllerGetStrategy } from "@kinty-app/api";

/** Pages */
import { Strategy } from "./pages/Strategy/Strategy";
import { useParams } from "react-router-dom";

export const StrategyContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useStrategyControllerGetStrategy({
    pathParams: { id: id || "" },
  });

  if (isLoading) {
    return null;
  }

  return <Strategy strategy={data} />;
};
