/**
 *  Kinty / App / Contexts / Theme context
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Theme context that controls theme specific variables
 *
 */

/** Dependencies */
import React, { useContext, createContext, useState, useMemo } from "react";
import { ThemeProvider as UIThemeProvider, Theme } from "@kinty-app/ui";

interface ThemeContextValue {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const ThemeContext = createContext<ThemeContextValue>({
  theme: Theme.LIGHT,
  setTheme: () => {},
});

export const useTheme = (): ThemeContextValue => useContext(ThemeContext);

interface ThemeProviderProps {
  children?: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(Theme.LIGHT);
  const value = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme, setTheme]
  );
  return (
    <ThemeContext.Provider value={value}>
      <UIThemeProvider theme={theme} children={children} />
    </ThemeContext.Provider>
  );
};
