/**
 *  Kinty / App / Features / Watchlists / Dialogs / Name
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Dialog that renders the input field for the name of the watchlist
 *
 */

/** Dependencies */
import React from "react";
import { Dialog, Button } from "@kinty-app/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

/** Styles */
import styles from "./WatchlistName.module.scss";
import { TextField } from "../../../components/hook-form-components";

/** Error Messages map */
enum ErrorMessage {
  "NAME_REQUIRED" = "This field is required",
}

/** The Watchlist Name Schema */
const WatchlistNameSchema = Yup.object().shape({
  name: Yup.string().required(ErrorMessage.NAME_REQUIRED),
});

/** The Watchlist data */
export interface WatchlistNameData {
  name: string;
}

/** Props */
interface WatchlistNameProps {
  onClose?: () => void;
  onSubmit: (data: WatchlistNameData) => void;
  open: boolean;
  name?: string;
  type: "create" | "update";
}

export const WatchlistsName: React.FC<WatchlistNameProps> = ({
  onClose,
  onSubmit,
  open,
  type,
  name = "",
}) => {
  const { control, handleSubmit } = useForm<WatchlistNameData>({
    mode: "onBlur",
    defaultValues: { name },
    resolver: yupResolver(WatchlistNameSchema),
  });

  return (
    <Dialog
      title={type === "create" ? "New Watchlist" : "Rename Watchlist"}
      onClose={onClose}
      visible={open}
      className={styles.root}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          control={control}
          label="Watchlist name"
          placeholder="e.g. Promising stocks"
          name="name"
        />
        <div className={styles.actions}>
          <Button className={styles.button} type="submit">
            {type === "create" ? "Create Watchlist" : "Rename Watchlist"}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
