/**
 *  Kinty / App / Components / Hook Form Components / Select Field
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Hook Form select field
 *
 */

/** Dependencies */
import React from "react";
import { Select, SelectProps } from "@kinty-app/ui";
import { Control, Controller, ControllerProps, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

/** Props */
export type SelectFieldElementProps<T extends FieldValues = FieldValues> = Omit<
  SelectProps,
  "name" | "onChange"
> & {
  validation?: ControllerProps["rules"];
  name: Path<T>;
  control: Control<T>;
};

export const SelectField = <TFieldValues extends FieldValues = FieldValues>({
  validation = {},
  name,
  control,
  message,
  ...rest
}: SelectFieldElementProps<TFieldValues>): JSX.Element => (
  <Controller
    name={name}
    control={control}
    rules={validation}
    render={({
      field: { value, onChange },
      fieldState: { error, isTouched },
    }) => (
      <Select
        {...rest}
        value={value ?? ""}
        onChange={onChange}
        state={error ? "error" : undefined}
        message={isTouched && error ? error.message : message}
      />
    )}
  />
);
