/**
 *  Kinty / Web / Pages / Forgotten Password
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Forgotten Password Page
 *
 */

/** Dependencies */
import React, { useCallback } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Button,
  Caption,
  Notification,
  Link,
  ButtonText,
  Separator,
} from "@kinty-app/ui";
import { UserException, useUserControllerPasswordReset } from "@kinty-app/api";

/** Local Dependencies */
import { CredentialsLayout } from "../../components/CredentialsLayout/CredentialsLayout";
import { TextField } from "../../components/hook-form-components";

/** Styles */
import styles from "./ForgottenPassword.module.scss";

/** Error Messages map */
enum ErrorMessage {
  "NOT_FOUND" = "User with this e-mail was not found",
  "INVALID_EMAIL" = "Invalid e-mail",
  "EMAIL_REQUIRED" = "E-mail is required",
}

/** Success messages map */
enum SuccessMessage {
  "EMAIL_SENT" = "An e-mail with instructions has been sent",
}

/** The Forgotten Password Form data structure */
interface ForgottenPasswordFormData {
  email: string;
}

/** The Sign In Schema */
const ForgottenPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(ErrorMessage.INVALID_EMAIL)
    .required(ErrorMessage.EMAIL_REQUIRED),
});

export const ForgottenPassword: React.FC = () => {
  const {
    mutate: resetPassword,
    isSuccess,
    error,
  } = useUserControllerPasswordReset();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<ForgottenPasswordFormData>({
    mode: "onBlur",
    defaultValues: { email: "" },
    resolver: yupResolver(ForgottenPasswordSchema),
  });

  const onSubmit = useCallback(
    async (body: ForgottenPasswordFormData) => {
      await resetPassword({ body }, { onSuccess: () => reset() });
    },
    [resetPassword, reset]
  );

  return (
    <CredentialsLayout
      header={
        <ButtonText level="2">
          Sorry, we are currently closed for new registrations.
        </ButtonText>
      }
      title="Recover Password"
      imageClassName={styles.image}
    >
      <Caption level="2" variant="regular" className={styles.text}>
        We will send you an e-mail with instructions for the actions you need to
        take in order to reset your password.
      </Caption>
      <Separator />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {error && !isSubmitting && (
          <Notification type="error" className={styles.notification}>
            {error.message === UserException.USER_NOT_FOUND
              ? ErrorMessage.NOT_FOUND
              : error.message}
          </Notification>
        )}
        {isSuccess && !isSubmitting && (
          <Notification type="success" className={styles.notification}>
            {SuccessMessage.EMAIL_SENT}
          </Notification>
        )}
        <TextField
          control={control}
          className={styles.input}
          label="E-mail"
          autoComplete="username"
          name="email"
        />
        <Button
          type="submit"
          className={classNames(styles.submit)}
          disabled={isSubmitting}
          stretch
        >
          Recover
        </Button>
        <Caption level="2" variant="bold" className={styles.links}>
          <Link component={NavLink} to="/sign-in">
            Nevermind, I got it?
          </Link>
        </Caption>
      </form>
    </CredentialsLayout>
  );
};
