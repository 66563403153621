/**
 *  Kinty / Web / Pages / Reset Password
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Reset Password Page
 *
 */

/** Dependencies */
import React, { useCallback } from "react";
import classNames from "classnames";
import { useParams, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button, Notification, Link, ButtonText } from "@kinty-app/ui";
import { useUserControllerChangePassword, UserException } from "@kinty-app/api";

/** Local Dependencies */
import { CredentialsLayout } from "../../components/CredentialsLayout/CredentialsLayout";
import { TextField } from "../../components/hook-form-components";

/** Styles */
import styles from "./ResetPassword.module.scss";

/** Error Messages map */
enum ErrorMessage {
  "INVALID_TOKEN" = "Error while trying to reset the password!",
  "PASSWORD_REQUIRED" = "Password is required",
  "CONFIRM_PASSWORD_REQUIRED" = "Password confirmation is required",
  "CONFIRM_PASSWORD_MISMATCH" = "Passwords don't match",
}

/** Success Messages map */
enum SuccessMessage {
  "PASSWORD_RESET" = "The password was successfully changed.",
}

/** The Reset Password Form data structure */
interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}

/** The Sign In Schema */
const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required(ErrorMessage.PASSWORD_REQUIRED),
  confirmPassword: Yup.string()
    .required(ErrorMessage.CONFIRM_PASSWORD_REQUIRED)
    .equals([Yup.ref("password")], ErrorMessage.CONFIRM_PASSWORD_MISMATCH),
});

export const ResetPassword: React.FC = () => {
  const params = useParams();
  const token = params.token || "";

  const {
    mutate: changePassword,
    isSuccess,
    error,
  } = useUserControllerChangePassword();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<ResetPasswordFormData>({
    mode: "onBlur",
    defaultValues: { password: "", confirmPassword: "" },
    resolver: yupResolver(ResetPasswordSchema),
  });

  const onSubmit = useCallback(
    async ({ password }: ResetPasswordFormData) => {
      await changePassword(
        { body: { password, token } },
        { onSuccess: () => reset() }
      );
    },
    [changePassword, token, reset]
  );

  return (
    <CredentialsLayout
      header={
        <ButtonText level="2">
          Already have an account?{" "}
          <Link to="/sign-in" component={NavLink}>
            Sign In
          </Link>
        </ButtonText>
      }
      title="Reset Password"
      imageClassName={classNames(styles.image)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {error && !isSubmitting && (
          <Notification type="error" className={styles.notification}>
            {error.message === UserException.INVALID_TOKEN
              ? ErrorMessage.INVALID_TOKEN
              : error.message}
          </Notification>
        )}
        {isSuccess && !isSubmitting && (
          <Notification type="success" className={styles.notification}>
            {SuccessMessage.PASSWORD_RESET}
          </Notification>
        )}
        <TextField
          control={control}
          className={styles.input}
          label="Password"
          type="password"
          name="password"
        />
        <TextField
          control={control}
          className={styles.input}
          label="Password Confirmation"
          type="password"
          name="confirmPassword"
        />
        <Button
          type="submit"
          className={classNames(styles.submit)}
          stretch
          disabled={isSubmitting}
        >
          Confirm
        </Button>
      </form>
    </CredentialsLayout>
  );
};
