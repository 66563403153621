/**
 *  Kinty / App / Contexts / Symbols
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The symbols context
 *
 */

/** Dependencies */
import React, { useContext, createContext, useMemo } from "react";
import {
  useSymbolControllerGetSymbols,
  SymbolControllerGetSymbolsResponse,
} from "@kinty-app/api";

interface SymbolsContextValue {
  isInitialized?: boolean;
  refresh?: () => void;
  symbols: SymbolControllerGetSymbolsResponse;
}

const SymbolsContext = createContext<SymbolsContextValue>({
  isInitialized: false,
  symbols: [],
});

export const useSymbols = (): SymbolsContextValue => {
  const values = useContext(SymbolsContext);
  if (!values.refresh) {
    throw new Error(`useSymbols must be used within a SymbolsProvider`);
  }
  return values;
};

interface SymbolsProviderProps {
  children?: React.ReactNode;
}

export const SymbolsProvider: React.FC<SymbolsProviderProps> = ({
  children,
}) => {
  const { data, refetch } = useSymbolControllerGetSymbols(
    {},
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );
  const value = useMemo(
    () => ({
      symbols: data || [],
      isInitialized: !!data,
      refresh: refetch,
    }),
    [refetch, data]
  );
  return <SymbolsContext.Provider value={value} children={children} />;
};
