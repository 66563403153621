/**
 *  Kinty / App / Features / Strategies / Pages / Strategies List
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategies Page
 *
 */

/** Dependencies */
import React from "react";
import { Link } from "react-router-dom";
import { StrategyResponse } from "@kinty-app/api";
import {
  Button,
  Headline,
  Card,
  Separator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from "@kinty-app/ui";

/** Styles */
import styles from "./StrategiesList.module.scss";

/** Props */
interface StrategiesListProps {
  strategies: StrategyResponse[];
}

export const StrategiesList: React.FC<StrategiesListProps> = ({
  strategies,
}) => (
  <Card className={styles.root}>
    <div className={styles.header}>
      <Headline level="4" className={styles.title}>
        Strategies
      </Headline>
      <div className={styles.actions}>
        <Button
          variant="secondary"
          size="small"
          className={styles.button}
          component={Link}
          to="/strategies/create"
        >
          Create a strategy
        </Button>
      </div>
    </div>
    <Separator className={styles.separator} />
    <Table>
      <TableHead>
        <TableHeadRow>
          <TableHeadCell>Name</TableHeadCell>
          <TableHeadCell align="right">State</TableHeadCell>
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {strategies?.map((strategy) => (
          <TableRow key={strategy.id} className={styles.row}>
            <TableCell>{strategy.name}</TableCell>
            <TableCell align="right">
              <div className={styles.progress}>
                {strategy.state} ( {strategy.progressPercent * 100}% )
              </div>
              <Button
                size="small"
                variant="primary"
                className={styles.viewButton}
                component={Link}
                to={`/strategies/${strategy.id}`}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Card>
);
