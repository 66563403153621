/**
 *  Kinty / App / Contexts / Notifications
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Notifications context
 *
 */

/** Dependencies */
import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
} from "react";

interface Notification {
  id: string;
  type: "warning" | "info" | "success" | "error";
  content: React.ReactNode;
}

interface NotificationsContextValue {
  notifications: Notification[];
  add: (notification: Notification) => void;
  dismiss: (id: string) => void;
}

const NotificationsContext = createContext<NotificationsContextValue>({
  notifications: [],
  add: () => {},
  dismiss: () => {},
});

export const useNotifications = (): NotificationsContextValue => {
  const values = useContext(NotificationsContext);
  if (!values.notifications) {
    throw new Error(
      `useNotifications must be used within a NotificationsProvider`
    );
  }
  return values;
};

interface NotificationsProviderProps {
  children?: React.ReactNode;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const add = useCallback(
    (notification: Notification) => {
      setNotifications((notifications) => {
        if (notifications.find(({ id }) => id === notification.id)) {
          return notifications;
        } else {
          return notifications.concat(notification);
        }
      });
    },
    [setNotifications]
  );
  const dismiss = useCallback(
    (id: string) => {
      setNotifications((notifications) => {
        if (notifications.find((notification) => notification.id === id)) {
          return notifications.filter((notification) => notification.id !== id);
        } else {
          return notifications;
        }
      });
    },
    [setNotifications]
  );
  const value = useMemo(
    () => ({
      add,
      dismiss,
      notifications,
    }),
    [notifications, add, dismiss]
  );
  return <NotificationsContext.Provider value={value} children={children} />;
};
