/**
 *  Kinty / Web / Components / Notifications
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Renders the current notifications in the notification context
 *
 */

/** Dependencies */
import React from "react";
import { Notification } from "@kinty-app/ui";

/** Local Dependencies */
import { useNotifications } from "../../contexts/notifications-context";

/** Styles */
import styles from "./Notifications.module.scss";

/** Space between two notifications */
const NOTIFICATION_MARGIN = 10;

export const Notifications: React.FC = () => {
  const { notifications, dismiss } = useNotifications();

  return (
    <div className={styles.root}>
      {notifications.map((notification, index) => (
        <div
          className={styles.notification}
          key={notification.id}
          style={{
            transform: `translateY(${NOTIFICATION_MARGIN * index}px)`,
          }}
        >
          <Notification
            type={notification.type}
            className={styles.notificationBody}
            absolute
            onDismiss={() => dismiss(notification.id)}
          >
            {notification.content}
          </Notification>
        </div>
      ))}
    </div>
  );
};
