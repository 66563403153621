/**
 *  Kinty / App / Components / Layout
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Holds the main layout structure
 *
 */

/** Dependencies */
import React from "react";
import classNames from "classnames";
import { Content } from "@kinty-app/ui";

/** Styles */
import styles from "./Layout.module.scss";

/** Props */
type LayoutProps = {
  empty?: boolean;
  children?: React.ReactNode;
};

export const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  empty,
}) => (
  <div className={classNames(styles.root, empty && styles.empty)}>
    {empty ? (
      children
    ) : (
      <div className={styles.contentWrapper}>
        <Content className={styles.content}>
          <div className={styles.page}>{children}</div>
        </Content>
      </div>
    )}
  </div>
);
