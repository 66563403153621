/**
 *  Kinty / Web / Components / Header
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Holds the header structure
 *
 */

/** Dependencies */
import React from "react";
import {
  Header as UIHeader,
  HeaderDivider,
  HeaderNavigation,
  HeaderNavigationLink,
  HeaderNavigationLinkActiveClassName,
} from "@kinty-app/ui";

/** Local Dependencies */
import { Logo } from "../Logo/Logo";
import { HeaderAvatar } from "./HeaderAvatar";
import { NavLink } from "../../utils/NavLink";

/** Styles */
import styles from "./Header.module.scss";

/** Props */
type HeaderProps = {
  className: string;
};

export const Header: React.FunctionComponent<HeaderProps> = ({ className }) => (
  <UIHeader fixed className={className}>
    <Logo />
    <HeaderDivider />
    <div className={styles.content}>
      <HeaderNavigation>
        <HeaderNavigationLink
          to="/dashboard/gainers"
          activeClassName={HeaderNavigationLinkActiveClassName}
          component={NavLink}
        >
          Dashboard
        </HeaderNavigationLink>
        <HeaderNavigationLink
          to="/watchlists"
          activeClassName={HeaderNavigationLinkActiveClassName}
          component={NavLink}
        >
          Watchlists
        </HeaderNavigationLink>
        <HeaderNavigationLink
          to="/strategies"
          activeClassName={HeaderNavigationLinkActiveClassName}
          component={NavLink}
        >
          Strategies
        </HeaderNavigationLink>
      </HeaderNavigation>
      <HeaderAvatar />
    </div>
  </UIHeader>
);
