/**
 *  Kinty / App
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The web application for Kinty
 *
 */

/** Dependencies */
import React from "react";
import { createRoot } from "react-dom/client";

/** Local Dependencies */
import App from "./App";

/** Styles */
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
