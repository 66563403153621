/**
 *  Kinty / App / Features / Watchlists
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Watchlists
 *
 */

/** Dependencies */
import React, { useCallback, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

/** Pages */
import { Watchlists } from "./pages/Watchlists";
import { WatchlistsEmpty } from "./pages/WatchlistsEmpty";
import {
  useWatchlistControllerCreateWatchlist,
  useWatchlistControllerDeleteWatchlist,
  useWatchlistControllerGetWatchlists,
  useWatchlistControllerUpdateWatchlist,
} from "@kinty-app/api";
import { WatchlistNameData } from "./dialogs/WatchlistName";

export const WatchlistsContainer: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const { data, refetch } = useWatchlistControllerGetWatchlists({});
  const { mutate: createWatchlist } = useWatchlistControllerCreateWatchlist({
    onSuccess: async (data) => {
      await refetch();
      navigate(`/watchlists/${data.id}`);
    },
  });
  const { mutate: updateWatchlist } = useWatchlistControllerUpdateWatchlist({
    onSuccess: () => refetch(),
  });
  const { mutate: deleteWatchlist } = useWatchlistControllerDeleteWatchlist({
    onSuccess: async () => {
      await refetch();
      navigate("/watchlists");
    },
  });

  useEffect(() => {
    const watchlistId = data?.[0]?.id;
    if (!id && watchlistId) {
      navigate(`/watchlists/${watchlistId}`);
    }
  }, [data, id, navigate]);

  const handleCreate = useCallback(
    (body: WatchlistNameData) => {
      createWatchlist({ body });
    },
    [createWatchlist]
  );

  const handleUpdate = useCallback(
    (id: string, body: WatchlistNameData) => {
      updateWatchlist({ pathParams: { id }, body });
    },
    [updateWatchlist]
  );

  const handleSelect = useCallback(
    (id: string) => {
      navigate(`/watchlists/${id}`);
    },
    [navigate]
  );

  const handleDelete = useCallback(
    (id: string) => {
      deleteWatchlist({ pathParams: { id } });
    },
    [deleteWatchlist]
  );

  if (data?.length === 0) {
    return <WatchlistsEmpty onCreate={handleCreate} />;
  } else {
    if (!id) {
      return null;
    }
    return (
      <Watchlists
        watchlists={data || []}
        selected={id}
        onCreate={handleCreate}
        onChange={handleSelect}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
      />
    );
  }
};
