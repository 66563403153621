/**
 *  Kinty / Web / Components / Header / Avatar
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The avatar for the header
 *
 */

/** Dependencies */
import React, { useCallback } from "react";
import {
  Avatar,
  ContextMenu,
  ContextMenuItem,
  Icons,
  Theme,
  Toggle,
} from "@kinty-app/ui";
import { useAuthControllerSignOut } from "@kinty-app/api";

/** Styles */
import styles from "./HeaderAvatar.module.scss";

/** Local Dependencies */
import { useUser } from "../../contexts/user-context";
import { useTheme } from "../../contexts/theme-context";

export const HeaderAvatar: React.FC = () => {
  const { refresh, user } = useUser();
  const { setTheme, theme } = useTheme();
  const { mutate: signOut } = useAuthControllerSignOut({
    onSuccess: () => refresh && refresh(),
  });

  const handleThemeChange = useCallback(() => {
    setTheme(theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
  }, [setTheme, theme]);

  const handleSignOutClick = useCallback(async () => {
    await signOut({});
  }, [signOut]);

  return (
    <Avatar imageUrl={user?.avatarUrl || ""}>
      <ContextMenu className={styles.root}>
        <ContextMenuItem
          icon={Icons.brightnessUpLine}
          title="Dark mode"
          description="Switch dark/light mode"
          action={
            <Toggle
              onChange={handleThemeChange}
              checked={theme === Theme.DARK}
            />
          }
        />
        <ContextMenuItem
          onClick={handleSignOutClick}
          icon={Icons.shareSquareLine}
          title="Log out"
        />
      </ContextMenu>
    </Avatar>
  );
};
