/**
 *  Kinty / App / Features / Watchlists / Pages / Watchlists
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Watchlists Page
 *
 */

/** Dependencies */
import React, { useRef, useCallback, useState, useMemo } from "react";
import {
  ContextMenu,
  ContextMenuItem,
  Select,
  Button,
  Card,
  Separator,
  Icons,
  IconButton,
  Popper,
  TableRow,
  TableCell,
  SymbolBadge,
  TableHead,
  TableHeadRow,
  TableHeadCell,
  Table,
  TableBody,
} from "@kinty-app/ui";
import { useOutsideClick } from "rooks";
import {
  useWatchlistControllerAddSymbol,
  useWatchlistControllerGetWatchlist,
  useWatchlistControllerRemoveSymbol,
  getSymbolLogoUrl,
} from "@kinty-app/api";

/** Local Dependencies */
import { WatchlistsName, WatchlistNameData } from "../dialogs/WatchlistName";
import { DestructiveDialog } from "../../../dialogs/DestructiveDialog";
import { WatchlistsSymbols } from "../dialogs/WatchlistSymbols";

/** Styles */
import styles from "./Watchlists.module.scss";

/** Props */
interface WatchlistsProps {
  watchlists: Array<{ name: string; id: string }>;
  selected: string;
  onCreate: (data: WatchlistNameData) => void;
  onUpdate: (id: string, data: WatchlistNameData) => void;
  onChange: (id: string) => void;
  onDelete: (id: string) => void;
}

export const Watchlists: React.FC<WatchlistsProps> = ({
  watchlists,
  selected,
  onCreate,
  onUpdate,
  onChange,
  onDelete,
}) => {
  const [createOpen, setCreateOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [symbolsOpen, setSymbolsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const contextMenu = useRef(null);

  const { data, refetch } = useWatchlistControllerGetWatchlist({
    pathParams: { id: selected },
  });

  const { mutate: removeSymbol } = useWatchlistControllerRemoveSymbol({
    onSuccess: () => refetch(),
  });

  const { mutate: addSymbol } = useWatchlistControllerAddSymbol({
    onSuccess: () => refetch(),
  });

  const selectedSymbols = useMemo(
    () => data?.symbols?.map(({ symbol }) => symbol) || [],
    [data]
  );

  const handleToggleSymbol = useCallback(
    (symbol: string) => {
      if (selectedSymbols.includes(symbol)) {
        removeSymbol({ pathParams: { id: selected, symbolId: symbol } });
      } else {
        addSymbol({ pathParams: { id: selected, symbolId: symbol } });
      }
    },
    [selected, selectedSymbols, addSymbol, removeSymbol]
  );

  useOutsideClick(contextMenu, () => setSettingsOpen(false));

  const handleSymbolsClose = useCallback(
    () => setSymbolsOpen(false),
    [setSymbolsOpen]
  );
  const handleCreateDialogClose = useCallback(
    () => setCreateOpen(false),
    [setCreateOpen]
  );
  const handleUpdateDialogClose = useCallback(
    () => setUpdateOpen(false),
    [setUpdateOpen]
  );
  const handleDeleteDialogClose = useCallback(
    () => setDeleteOpen(false),
    [setDeleteOpen]
  );

  const handleSymbolsClick = useCallback(
    () => setSymbolsOpen(true),
    [setSymbolsOpen]
  );
  const handleSettingsClick = useCallback(
    () => setSettingsOpen(true),
    [setSettingsOpen]
  );
  const handleCreateClick = useCallback(
    () => setCreateOpen(true),
    [setCreateOpen]
  );
  const handleUpdateClick = useCallback(
    () => setUpdateOpen(true),
    [setUpdateOpen]
  );
  const handleDeleteClick = useCallback(
    () => setDeleteOpen(true),
    [setDeleteOpen]
  );

  const handleCreateSubmit = useCallback(
    (data: WatchlistNameData) => {
      onCreate(data);
      setCreateOpen(false);
    },
    [setCreateOpen, onCreate]
  );
  const handleUpdateSubmit = useCallback(
    (data: WatchlistNameData) => {
      onUpdate(selected, data);
      setUpdateOpen(false);
    },
    [setUpdateOpen, onUpdate, selected]
  );
  const handleDeleteSubmit = useCallback(() => {
    onDelete(selected);
    setDeleteOpen(false);
  }, [setDeleteOpen, onDelete, selected]);

  const name = watchlists.find(({ id }) => id === selected)?.name;

  return (
    <Card className={styles.root}>
      <div className={styles.header}>
        <div className={styles.list}>
          <Select
            options={watchlists.map(({ name, id }) => ({ id, label: name }))}
            value={selected}
            onChange={onChange}
          >
            <ContextMenu>
              <ContextMenuItem
                icon={Icons.plusSquareLine}
                title="New Watchlist"
                onClick={handleCreateClick}
              />
            </ContextMenu>
          </Select>
        </div>
        <div className={styles.actions}>
          <Button
            variant="secondary"
            size="small"
            onClick={handleSymbolsClick}
            className={styles.actionButton}
          >
            Add Symbols
          </Button>
          <IconButton
            icon={Icons.cogLine}
            ref={setAnchorEl}
            className={styles.settingsButton}
            onClick={handleSettingsClick}
          />
          <div ref={contextMenu}>
            <Popper anchorEl={anchorEl as Element} open={settingsOpen}>
              <ContextMenu className={styles.settingsMenu}>
                <ContextMenuItem
                  icon={Icons.pencilLine}
                  title="Rename"
                  onClick={handleUpdateClick}
                />
                <ContextMenuItem
                  icon={Icons.closeCircleLine}
                  title="Delete"
                  onClick={handleDeleteClick}
                />
              </ContextMenu>
            </Popper>
          </div>
        </div>
      </div>
      <Separator className={styles.separator} />
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Symbol / Name</TableHeadCell>
            <TableHeadCell align="right">Price</TableHeadCell>
            <TableHeadCell align="right">Change</TableHeadCell>
            <TableHeadCell align="right">Change (%)</TableHeadCell>
            <TableHeadCell align="right">Volume</TableHeadCell>
            <TableHeadCell align="right">Market Cap</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {data?.symbols?.map((symbol) => (
            <TableRow key={symbol.symbol}>
              <TableCell>
                <SymbolBadge
                  ticker={symbol.symbol}
                  name={symbol.name}
                  image={getSymbolLogoUrl(symbol.symbol)}
                />
              </TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DestructiveDialog
        onClose={handleDeleteDialogClose}
        onSubmit={handleDeleteSubmit}
        open={deleteOpen}
        message="Are you sure you want to delete this watchlist?"
        title="Delete Watchlist"
      />
      <WatchlistsSymbols
        onClick={handleToggleSymbol}
        selected={selectedSymbols}
        open={symbolsOpen}
        onClose={handleSymbolsClose}
      />
      <WatchlistsName
        type="create"
        open={createOpen}
        onClose={handleCreateDialogClose}
        onSubmit={handleCreateSubmit}
      />
      <WatchlistsName
        type="update"
        open={updateOpen}
        name={name}
        onClose={handleUpdateDialogClose}
        onSubmit={handleUpdateSubmit}
      />
    </Card>
  );
};
